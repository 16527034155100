import React from 'react';
import { Redirect } from 'react-router-dom';
import { Apibase } from './Apibase';

class Login extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            redirectToReferrer: false,
            username: '',
            password: '',
            failedLogin: false,
        };
    }

    handleUsernameChange(e) {
        this.setState({ username: e.target.value });
    }
    handlePasswordChange(e) {
        this.setState({ password: e.target.value });
    }

    login = async () => {
        //alert(this.state.username)
        //alert(this.state.password)

        await fetch(Apibase + '/api/users/login', {
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'POST',
            body: JSON.stringify({
                Username: this.state.username,
                Password: this.state.password,
            }),
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Unathorized');
                }
            })
            .then((result) => {
                localStorage.setItem('loggedin', true);
                localStorage.setItem('accessToken', result.AccessToken);
                localStorage.setItem('accessTokenExp', Date.now() + 720000);
                localStorage.setItem('refreshToken', result.RefreshToken);
                localStorage.setItem('refreshing', 'false');
                this.props.nowloggedin();
                this.setState(() => ({
                    redirectToReferrer: true,
                }));
            })
            .catch((error) => {
                this.setState({ failedLogin: true });
            });
    };

    render() {
        const { from } = this.props.location.state || {
            from: { pathname: '/' },
        };
        const { redirectToReferrer } = this.state;

        if (redirectToReferrer === true) {
            return (
                <div>
                    <Redirect to={from.pathname} />
                </div>
            );
        }

        return (
            <div className="container justify-content-center">
                <p className="col-12 text-center">Please login to continue</p>
                <div className="row">
                    <div className="col-6  text-right ">
                        <label htmlFor="username">Username: </label>
                    </div>

                    <div className="col-3  text-left  pl-0 pb-3">
                        <input
                            className="form-control"
                            onChange={(e) => this.handleUsernameChange(e)}
                            type="text"
                            id="username"
                            name="username"
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-6  text-right  ">
                        <label htmlFor="password">Password: </label>
                    </div>

                    <div className="col-3  text-left pb-3 pl-0">
                        <input
                            className="form-control"
                            onChange={(e) => this.handlePasswordChange(e)}
                            type="password"
                            id="password"
                            name="password"
                        />
                    </div>
                </div>
                <div className="text-center">
                    <button className="btn btn-primary" onClick={this.login}>
                        Log in
                    </button>
                </div>
                <div className="text-center">
                    <b>
                        {this.state.failedLogin
                            ? 'Incorrect Username or Password'
                            : ''}
                    </b>
                </div>
            </div>
        );
    }
}

export default Login;
