import './App.css';
import ualogo from './Utilityaid_logo.png';
import React, { useState } from 'react';
import QuoteList from './QuoteList';
import BasketQuoteList from './BasketQuotesList';
import SelectPricesToInclude from './SelectPricesToInclude';
import ViewCompletedQuote from './ViewCompletedQuote';
import Login from './login';
import DashboardParent from './SummaryDashboard';
import BasketManagement from './BasketManagement';

import { Switch, Route, Link, Redirect, useHistory } from 'react-router-dom';

const ProtectedRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={(props) =>
            localStorage.getItem('loggedin') === 'true' ? (
                <Component {...props} />
            ) : (
                <Redirect
                    to={{
                        pathname: '/login',
                        state: { from: props.location },
                    }}
                />
            )
        }
    />
);

function App() {
    let history = useHistory();

    const [loggedin, setloggedIn] = useState(localStorage.getItem('loggedin'));

    function logout() {
        localStorage.setItem('loggedin', false);
        localStorage.setItem('accessToken', '');
        localStorage.setItem('refreshToken', '');
        localStorage.setItem('accessTokenExp', '');
        //window.location.href = "/login";
        setloggedIn(false);
        history.push('/login');
    }

    const nowloggedin = () => {
        setloggedIn('true');
    };
    let userObj = {};
    if (loggedin) {
        try {
            userObj = JSON.parse(
                window.atob(localStorage.getItem('accessToken').split('.')[1])
            );
        } catch (e) {
            logout();
        }
    }

    return (
        <div className="App">
            <div className="container-fluid">
                <div className="appheader p-4 mb-3 row justify-content-between">
                    <img src={ualogo} alt="utility aid logo"></img>
                    {loggedin === 'true' ? (
                        <div className="d-flex pr-5">
                            <Link
                                to="/quotes"
                                className="nav-link text-white pr-5"
                            >
                                Quotes
                            </Link>

                            <Link
                                to="/BasketQuotes"
                                className="nav-link text-white pr-5"
                            >
                                Basket Quotes
                            </Link>

                            <Link
                                to="/SummaryDashboard"
                                className="nav-link text-white pr-5"
                            >
                                Dashboard
                            </Link>
                            {userObj.UserRole !== 'Specialist' ?
                                <Link
                                    to="/BasketManagement"
                                    className="nav-link text-white pr-5"
                                >
                                    Baskets 
                                </Link>
                                : null}
                            {/* <Link
                                to="/prices"
                                className="nav-link text-white pr-5"
                            >
                                Prices
                            </Link>
                            <Link
                                to="/viewquote"
                                className="nav-link text-white pr-5"
                            >
                                view Quote
                            </Link>
                            <Link
                                to="/prices"
                                className="nav-link text-white pr-5"
                                target="_blank"
                            >
                                Prices new tab
                            </Link> */}

                            <div
                                className="nav-link text-white pr-5"
                                style={{ cursor: 'pointer' }}
                                onClick={() => logout()}
                            >
                                Logout
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
                <Switch>
                    <ProtectedRoute path="/" exact component={QuoteList} />
                    <ProtectedRoute path="/quotes" component={QuoteList} />
                    <ProtectedRoute
                        path="/BasketQuotes"
                        component={BasketQuoteList}
                    />
                    <Route
                        path="/login"
                        render={(props) => (
                            <Login
                                nowloggedin={() => nowloggedin()}
                                {...props}
                            />
                        )}
                    />
                    <ProtectedRoute
                        path="/prices/:id"
                        component={SelectPricesToInclude}
                    />
                    <ProtectedRoute
                        path="/prices/:id"
                        component={SelectPricesToInclude}
                    />
                    <ProtectedRoute
                        path="/viewquote/:id"
                        component={ViewCompletedQuote}
                    />
                    <ProtectedRoute
                        path="/SummaryDashboard"
                        component={DashboardParent}
                    />
                    <ProtectedRoute
                        path="/BasketManagement"
                        component={BasketManagement}
                    />

                    <Route
                        render={(props) => (
                            <Login
                                nowloggedin={() => nowloggedin()}
                                {...props}
                            />
                        )}
                    />
                </Switch>
            </div>
        </div>
    );
}

export default App;
