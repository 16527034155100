import React from 'react';
import { Apibase } from './Apibase';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/button';
import fetch from './refreshTokenFetch';

import { download_table_as_csv } from './TableToExcel';

class ModalForm extends React.Component {
    state = { NewquoteStatus: '' };

    handleChange = (e) => {
        this.setState({ NewquoteStatus: e.target.value });
    };

    render() {
        return (
            <Modal show={this.props.isOpen} onHide={this.props.closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Please Select A Quote Status</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>
                            Please select a quote status for quote number:{' '}
                            {this.props.selectedQuote}
                        </Form.Label>

                        <Form.Control as="select" onChange={this.handleChange}>
                            <option default hidden>
                                Please Choose...
                            </option>
                            <option>Requesting Prices</option>
                            <option>Prices Requested</option>
                            <option>Prices Received</option>
                            <option>Quote Sent</option>
                            <option>Requesting Contracts</option>
                            <option>Contracts Sent</option>
                            <option>Customer Signed Contract</option>
                            <option>Submitted For Lock</option>
                            <option>Locked</option>
                            <option>Live</option>
                            <option>Lost</option>
                            <option>Cancelled</option>
                        </Form.Control>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        type="submit"
                        onClick={() =>
                            this.props.handleSubmit(this.state.NewquoteStatus)
                        }
                    >
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

class BasketQuoteList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            completedFilter: false,
            awaitingPricesFilter: true,
            quotes: [],

            quoteSelected: null,
            meters: false,
            prices: false,
            isOpen: false,
            filterboxes: [
                'Requesting Prices',
                'Prices Requested',
                'Prices Received',
            ],
            filterboxesdontinclude: [
                'Quote Sent',
                'Requesting Contracts',
                'Contracts Sent',
                'Customer Signed Contract',
                'Submitted For Lock',
                'Locked',
                'Live',
                'Lost',
                'Cancelled',
            ],
            fromDate: '01/01/2000',
            ToDate: '01/01/2100',
        };
    }

    componentDidMount() {
        this.getQuotes();
        this.setState({
            fromDate: this.addDays(new Date(), -365),
            ToDate: this.addDays(new Date(), 20),
        });
    }

    getQuotes() {
        fetch(Apibase + '/api/v1/quotes', {
            headers: {},
            method: 'GET',
        })
            .then((res) => res.json())
            .then(
                (result) => {
                    this.setState({
                        quotes: result,
                    });
                    //console.log('hit here third')
                },

                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        isLoaded: false,
                        error,
                    });
                }
            );
    }

    openModal = () => {
        if (this.state.quoteSelected === null) {
            alert('Please select a quote first!');
        } else {
            this.setState({ isOpen: true });
        }
    };
    closeModal = () => this.setState({ isOpen: false });

    handleSubmit = (quoteStatus) => {
        fetch(Apibase + '/api/v1/quotes/ChangeStatus', {
            headers: { 'Content-Type': 'application/json' },
            method: 'POST',
            body: JSON.stringify({
                NewStatus: quoteStatus,
                QuoteID: this.state.quoteSelected,
            }),
        }).then(() => {
            this.getQuotes();
        });
        /*
        var newquotes = this.state.quotes;
        for (var i in newquotes) {
            if (newquotes[i].ID === this.state.quoteSelected) {
                newquotes[i].quoteStatus = quoteStatus;
            }
        }
        this.setState({ quotes: newquotes });
*/
        this.closeModal();

        //this.setState({ quotes: newquotes });
    };

    awaitingpricesFilterChange() {
        if (this.state.awaitingPricesFilter === false) {
            this.setState({ awaitingPricesFilter: true });
        } else {
            this.setState({ awaitingPricesFilter: false });
        }
    }

    completedFilterChange() {
        if (this.state.completedFilter === false) {
            this.setState({ completedFilter: true });
        } else {
            this.setState({ completedFilter: false });
        }
    }

    openPrices = () => {
        this.props.history.push('/prices/' + this.state.quoteSelected);
    };

    quoteClicked(QuoteID) {
        fetch(Apibase + '/api/v1/meters/byQuote/' + QuoteID, {
            headers: {},
            method: 'GET',
        })
            .then((res) => res.json())
            .then(
                (result) => {
                    this.setState({
                        meters: result,
                    });
                    //console.log('hit here third')
                },

                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        isLoaded: false,
                        error,
                    });
                }
            );

        fetch(Apibase + '/api/v1/prices/byQuote/' + QuoteID, {
            headers: {},
            method: 'GET',
        })
            .then((res) => res.json())
            .then(
                (result) => {
                    this.setState({
                        prices: result,
                    });
                    //console.log('hit here third')
                },

                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        isLoaded: false,
                        error,
                    });
                }
            );

        this.setState({ quoteSelected: QuoteID });
    }

    searchquotes() {
        var input, filter, table, tr, i, txtValue, currtr;
        input = document.getElementById('myInput');
        filter = input.value.toUpperCase();
        table = document.getElementById('myTable');
        tr = table.getElementsByTagName('tr');

        for (i = 0; i < tr.length; i++) {
            currtr = tr[i];
            if (currtr) {
                txtValue = currtr.textContent || currtr.innerText;
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                    tr[i].style.display = '';
                } else {
                    tr[i].style.display = 'none';
                }
            }
        }
    }

    hideCertainRows = (arrayItem) => {
        var newarr = this.state.filterboxes;
        var newarrDontInclude = this.state.filterboxesdontinclude;

        var index = this.state.filterboxes.indexOf(arrayItem);
        var indexNotInnclude =
            this.state.filterboxesdontinclude.indexOf(arrayItem);

        if (index !== -1) {
            newarr.splice(index, 1);
            this.setState({ filterboxes: newarr });

            newarrDontInclude.push(arrayItem);
            this.setState({ filterboxesdontinclude: newarrDontInclude });
        } else {
            newarr.push(arrayItem);
            this.setState({ filterboxes: newarr });

            newarrDontInclude.splice(indexNotInnclude, 1);
            this.setState({ filterboxesdontinclude: newarrDontInclude });
        }

        //this.setState({ filterboxes: ["not here"] });
    };

    getQuotesClass = (
        QuoteID,
        QuoteStatus,
        PADate,
        BasketName,
        BasketActive
    ) => {
        var dateFrom = this.state.fromDate;
        var dateTo = this.state.ToDate;
        var dateCheck = PADate;

        var d1 = dateFrom.split('/');
        var d2 = dateTo.split('/');
        var c = dateCheck.split('/');

        var from = new Date(d1[2], parseInt(d1[1]) - 1, d1[0]); // -1 because months are from 0 to 11
        var to = new Date(d2[2], parseInt(d2[1]) - 1, d2[0]);
        var check = new Date(c[2], parseInt(c[1]) - 1, c[0]);

        var dateFine = check >= from && check <= to;

        var newClassName;

        if (
            this.state.filterboxes.includes(QuoteStatus) &&
            dateFine &&
            BasketName &&
            BasketActive === 1
        ) {
            QuoteID === this.state.quoteSelected
                ? (newClassName = 'table-primary table-sm')
                : (newClassName = 'table-sm');
        } else {
            newClassName = 'd-none';
        }

        return newClassName;
    };

    formatdate = (datetoformat) => {
        var dd = String(datetoformat.getDate()).padStart(2, '0');
        var mm = String(datetoformat.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = datetoformat.getFullYear();

        var formatteddate = dd + '/' + mm + '/' + yyyy;
        return formatteddate;
    };

    addDays = (theDate, days) => {
        var unformattedDate = new Date(
            theDate.getTime() + days * 24 * 60 * 60 * 1000
        );

        return this.formatdate(unformattedDate);
    };

    /*
    exportToCsv(tablID) {
        download_table_as_csv(tablID);
    }
*/

    handleInputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };
    render() {
        return (
            <div className="container-fluid">
                {this.state.isOpen ? (
                    <ModalForm
                        closeModal={this.closeModal}
                        isOpen={this.state.isOpen}
                        handleSubmit={this.handleSubmit}
                        selectedQuote={this.state.quoteSelected}
                    />
                ) : null}

                <div className="quotelistMain">
                    <div className="quotelistHeader row">
                        <div className="col-md-4 col-xl-4">
                            <h1>Basket Quotes</h1>
                            <div className="row">
                                <div className="col-4">
                                    <h4>Filter quotes:</h4>
                                </div>
                                <div className="col-8 ">
                                    <input
                                        autoComplete="off"
                                        className="form-control align-middle "
                                        type="text"
                                        id="myInput"
                                        onKeyUp={() => this.searchquotes()}
                                        placeholder="Search for quotes.."
                                    ></input>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-lg-2 col-xl-1">
                            <h3>filters:</h3>
                        </div>
                        <div className=" col-12 col-md-6 col-xl-3">
                            {/* <input
                                type="checkbox"
                                id="completed"
                                name="completed"
                                value="Requesting Prices"
                                checked={this.state.filterboxes.includes("Requesting Prices")}
                                onChange={() => this.hideCertainRows("Requesting Prices")}
                            ></input> */}
                            <label className="h6" htmlFor="selectDontInclude">
                                Dont Include (double click to move)
                            </label>
                            <select
                                multiple
                                className="form-control"
                                id="selectDontInclude"
                            >
                                {this.state.filterboxesdontinclude.map(
                                    (item, key) => {
                                        return (
                                            <option
                                                key={key}
                                                value={item}
                                                onDoubleClick={() =>
                                                    this.hideCertainRows(item)
                                                }
                                            >
                                                {item}
                                            </option>
                                        );
                                    }
                                )}
                            </select>
                        </div>

                        <div className=" col-12 col-md-6 col-xl-2">
                            <label className="h6" htmlFor="selectInclude">
                                Include
                            </label>
                            <select
                                multiple
                                className="form-control"
                                id="selectInclude"
                            >
                                {this.state.filterboxes.map((item, key) => {
                                    return (
                                        <option
                                            key={key}
                                            value={item}
                                            onDoubleClick={() =>
                                                this.hideCertainRows(item)
                                            }
                                        >
                                            {item}
                                        </option>
                                    );
                                })}
                            </select>
                            {/* <label className="pl-2" htmlFor="completed">
                Completed
              </label> */}

                            {/* <input
                type="checkbox"
                id="awaitingprices"
                name="awaitingprices"
                value="Prices Requested"
                checked={this.state.filterboxes.includes("Prices Requested")}
                onChange={() => this.hideCertainRows("Prices Requested")}
              ></input> */}
                            {/* <label className="pl-2" htmlFor="awaitingprices">
                Awaiting Prices
              </label> */}
                        </div>
                        <div className=" col-12 col-md-6 col-xl-2">
                            <label className="h6" htmlFor="PAFrom">
                                PA From
                            </label>
                            <input
                                autoComplete="off"
                                className="form-control align-middle "
                                type="text"
                                id="PAFrom"
                                name="fromDate"
                                onChange={this.handleInputChange}
                                placeholder="PA From"
                                value={this.state.fromDate}
                            ></input>
                            <label className="h6" htmlFor="PATo">
                                PA To
                            </label>
                            <input
                                autoComplete="off"
                                className="form-control align-middle "
                                type="text"
                                id="PATo"
                                name="ToDate"
                                onChange={this.handleInputChange}
                                placeholder="PA To"
                                value={this.state.ToDate}
                            ></input>
                        </div>
                    </div>
                    <div className="row p-3 ">
                        <div className="quotes col-md-6 mb-4 mb-md-0 card">
                            <div className="row tableTitle">
                                <div className="col-md-6">
                                    <h2>Quotes</h2>
                                </div>
                                <div className="col-md-6">
                                    <button
                                        type="button"
                                        className="btn btn-link"
                                        href="#"
                                        onClick={() =>
                                            download_table_as_csv('quotestable')
                                        }
                                    >
                                        Download as CSV
                                    </button>
                                </div>
                            </div>
                            <div className="table-responsive quotesTable mb-3">
                                <table
                                    id="quotestable"
                                    className="table  table-bordered table-striped table-hover "
                                >
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Customer Name</th>
                                            <th>Quote Status</th>
                                            <th>No. Meters</th>
                                            <th>Pricing appointment date</th>
                                            <th>Sales Specialist</th>
                                            <th>Requested PPKWH Comms</th>
                                            <th>Requested SC Comms</th>
                                            <th>Notes</th>
                                            <th>Basket</th>
                                        </tr>
                                    </thead>
                                    <tbody className="" id="myTable">
                                        {this.state.quotes.map((item, key) => {
                                            return (
                                                <tr
                                                    key={key}
                                                    className={this.getQuotesClass(
                                                        item.QuoteIDPK,
                                                        item.QuoteStatus,
                                                        item.PricingAppointmentDate,
                                                        item.BasketName,
                                                        item.BasketActive
                                                    )}
                                                    onClick={() =>
                                                        this.quoteClicked(
                                                            item.QuoteIDPK
                                                        )
                                                    }
                                                >
                                                    <td>{item.QuoteIDPK}</td>
                                                    <td>{item.CustomerName}</td>
                                                    <td>{item.QuoteStatus}</td>
                                                    <td>
                                                        {item.NumberOfMeters}
                                                    </td>
                                                    <td>
                                                        {
                                                            item.PricingAppointmentDate
                                                        }
                                                    </td>
                                                    <td>{item.Username}</td>
                                                    <td>
                                                        {
                                                            item.RequestedPPKWHComms
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            item.RequestedStandingChargeComms
                                                        }
                                                    </td>
                                                    <td>{item.Notes}</td>
                                                    <td>{item.BasketName}</td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="metersandprices col-md-6">
                            <div className="card  p-3 mb-4">
                                <div className="row tableTitle">
                                    <div className="col-md-6">
                                        <h2>Meters</h2>
                                    </div>
                                    <div className="col-md-6">
                                        <button
                                            type="button"
                                            className="btn btn-link"
                                            href="#"
                                            onClick={() =>
                                                download_table_as_csv(
                                                    'meterstable'
                                                )
                                            }
                                        >
                                            Download as CSV
                                        </button>
                                    </div>
                                </div>

                                <div className="table-responsive metersTable">
                                    <table
                                        id="meterstable"
                                        className="table table-bordered table-sm"
                                    >
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Meter Number</th>
                                                <th>In Contract Until</th>
                                                <th>AQ Day</th>
                                                <th>AQ Night</th>
                                                <th>AQ Other</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.meters ? (
                                                this.state.meters.map(
                                                    (item, key) => {
                                                        return (
                                                            <tr key={key}>
                                                                <td>
                                                                    {
                                                                        item.MeterIDPK
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {
                                                                        item.MeterNumber
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {
                                                                        item.ContractEnd
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {item.DayAQ}
                                                                </td>
                                                                <td>
                                                                    {
                                                                        item.NightAQ
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {
                                                                        item.OtherAQ
                                                                    }
                                                                </td>
                                                            </tr>
                                                        );
                                                    }
                                                )
                                            ) : (
                                                <tr></tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="card  p-3 ">
                                <div className="row tableTitle">
                                    <div className="col-md-6">
                                        <h2>Prices</h2>
                                    </div>
                                    <div className="col-md-6">
                                        <button
                                            type="button"
                                            className="btn btn-link"
                                            href="#"
                                            onClick={() =>
                                                download_table_as_csv(
                                                    'pricestable'
                                                )
                                            }
                                        >
                                            Download as CSV
                                        </button>
                                    </div>
                                </div>

                                <div className="table-responsive pricesTable">
                                    <table
                                        id="pricestable"
                                        className="table table-bordered table-sm"
                                    >
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Supplier</th>
                                                <th>Price Name</th>
                                                <th>Total Annual Cost</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.prices ? (
                                                this.state.prices.map(
                                                    (item, key) => {
                                                        return (
                                                            <tr key={key}>
                                                                <td>
                                                                    {
                                                                        item.PriceGroupIDPK
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {
                                                                        item.SupplierName
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {item.PriceName
                                                                        ? item.PriceName.replace(
                                                                              /~,~/g,
                                                                              ','
                                                                          )
                                                                        : ''}
                                                                </td>
                                                                <td>
                                                                    {item.TotalAnnualCost
                                                                        ? item.TotalAnnualCost.toLocaleString(
                                                                              'en-UK',
                                                                              {
                                                                                  style: 'currency',
                                                                                  currency:
                                                                                      'GBP',
                                                                              }
                                                                          )
                                                                        : 0}
                                                                </td>
                                                            </tr>
                                                        );
                                                    }
                                                )
                                            ) : (
                                                <tr></tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row  p-3">
                        <div className="col-12 col-md-6 text-center py-1">
                            {/* <button type="button" className="btn btn-secondary">
                                Add Prices
                            </button> */}
                            <button
                                type="button"
                                onClick={this.openModal}
                                className="btn btn-secondary"
                            >
                                Change Quote Status
                            </button>
                        </div>
                        {/* <div className="col-md-3 d-flex justify-content-around">
                            <button type="button" className="btn btn-info">
                                View Price Details
                            </button>
                            <button type="button" className="btn btn-info">
                                View Meter Details
                            </button>
                        </div> */}
                        <div className="col-12 col-md-6  text-center py-1">
                            <button
                                onClick={this.openPrices}
                                type="button"
                                className="btn btn-success"
                            >
                                Select Prices To Include
                            </button>
                            {/* <button type="button" className="btn btn-success">
                                View Completed Quote
                            </button> */}
                        </div>
                    </div>
                    {/* <div className="row pt-3 ">
                        <div className="col-md-3 offset-3 d-flex justify-content-around">
                            <button type="button" className="btn btn-info">
                                View Customer Details
                            </button>
                        </div>
                    </div> */}
                </div>
            </div>
        );
    }
}

export default BasketQuoteList;
