import React from 'react';
import { Apibase } from './Apibase';
import fetch from './refreshTokenFetch';

// import Spinner from 'react-bootstrap/Spinner';
// import { download_table_as_csv } from './TableToExcel';

import {
    BarChart,
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    ResponsiveContainer,
    Bar,
    LabelList,
} from 'recharts';

class DashboardParent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            PeriodFrom: '01/01/2000',
            PeriodTo: '01/01/2100',
            PeriodFromURLFormat: '2000-01-01',
            PeriodToURLFormat: '2100-01-01',
        };
    }

    componentDidMount() {
        this.setDefaultPeriodFromAndTo();
    }

    setDefaultPeriodFromAndTo = () => {
        this.setState({
            PeriodFrom: this.addDays(new Date(), -1, 1),
            PeriodTo: this.addDays(new Date(), -1, 1),
            PeriodFromURLFormat: this.addDays(new Date(), -1, 0),
            PeriodToURLFormat: this.addDays(new Date(), -1, 0),
        });
        this.GetDashboardSummarydata(
            this.addDays(new Date(), -1, 0),
            this.addDays(new Date(), -1, 0)
        );
    };

    formatdate = (datetoformat, UKFormat) => {
        var dd = String(datetoformat.getDate()).padStart(2, '0');
        var mm = String(datetoformat.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = datetoformat.getFullYear();

        var formatteddate;
        if (UKFormat) {
            formatteddate = dd + '/' + mm + '/' + yyyy;
        } else {
            formatteddate = yyyy + '-' + mm + '-' + dd;
        }

        return formatteddate;
    };

    UKDateToURLDate = (datetoformat) => {
        var URLDate =
            datetoformat.split('/')[2] +
            '-' +
            datetoformat.split('/')[1] +
            '-' +
            datetoformat.split('/')[0];

        return URLDate;
    };

    testDate = (str) => {
        var t = str.match(/^(\d{2})\/(\d{2})\/(\d{4})$/);
        if (t === null) return false;
        var d = +t[1],
            m = +t[2];
        // y = +t[3];

        // Below should be a more acurate algorithm
        if (m >= 1 && m <= 12 && d >= 1 && d <= 31) {
            return true;
        }

        return false;
    };

    addDays = (theDate, days, UkFormat) => {
        var unformattedDate = new Date(
            theDate.getTime() + days * 24 * 60 * 60 * 1000
        );

        //console.log(unformattedDate);
        return this.formatdate(unformattedDate, UkFormat);
    };

    RefreshTheData = () => {
        if (
            this.testDate(this.state.PeriodFrom) &&
            this.testDate(this.state.PeriodTo)
        ) {
            var URLDateFrom = this.UKDateToURLDate(this.state.PeriodFrom);
            var URLDateTo = this.UKDateToURLDate(this.state.PeriodTo);

            this.setState({
                PeriodFromURLFormat: URLDateFrom,
                PeriodToURLFormat: URLDateTo,
            });

            this.GetDashboardSummarydata(URLDateFrom, URLDateTo);
        } else {
            alert('Please enter valid dates in the From And To fields');
        }
    };

    GetDashboardSummarydata = (PeriodFromURLFormat, PeriodToURLFormat) => {
        fetch(
            Apibase +
                '/api/v1/quotes/DashboardSummary/' +
                PeriodFromURLFormat +
                '/' +
                PeriodToURLFormat,
            {
                headers: {},
                method: 'GET',
            }
        )
            .then((res) => res.json())
            .then(
                (result) => {
                    this.setState({
                        RawDashData: result,
                    });
                    // console.log(result);
                    this.FormatTheDashData(result);
                },

                (error) => {
                    this.setState({
                        isLoaded: false,
                        error,
                    });
                }
            );
    };

    handleInputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    FormatTheDashData = (RawDashData) => {
        var DashDataOb = {};

        var GrandTotalAQ = 0;
        var GrandTotalEstimatedComms = 0;
        var GrandTotalCommsSigned = 0;
        var GrandTotalNumberQuotes = 0;
        var GrandTotalNumberQuotesSigned = 0;

        for (var i = 0; i < RawDashData.length; i++) {
            var currUsername = RawDashData[i].Username;

            if (!(currUsername in DashDataOb)) {
                DashDataOb[currUsername] = {};
                DashDataOb[currUsername].TotalAQ = 0;
                DashDataOb[currUsername].EstimatedAnnualComms = 0;
                DashDataOb[currUsername].SignedComms = 0;
                DashDataOb[currUsername].NumberOfQuotes = 0;
                DashDataOb[currUsername].NumberOfQuotesSigned = 0;
            }

            DashDataOb[RawDashData[i].Username].TotalAQ =
                DashDataOb[RawDashData[i].Username].TotalAQ +
                RawDashData[i].TotalAQ;

            GrandTotalAQ = GrandTotalAQ + RawDashData[i].TotalAQ;

            DashDataOb[RawDashData[i].Username].EstimatedAnnualComms =
                DashDataOb[RawDashData[i].Username].EstimatedAnnualComms +
                RawDashData[i].EstimatedAnnualComms;

            GrandTotalEstimatedComms =
                GrandTotalEstimatedComms + RawDashData[i].EstimatedAnnualComms;

            DashDataOb[RawDashData[i].Username].NumberOfQuotes =
                DashDataOb[RawDashData[i].Username].NumberOfQuotes + 1;

            GrandTotalNumberQuotes = GrandTotalNumberQuotes + 1;

            if (
                RawDashData[i].QuoteStatus === 'Submitted For Lock' ||
                RawDashData[i].QuoteStatus === 'Locked' ||
                RawDashData[i].QuoteStatus === 'Live'
            ) {
                DashDataOb[RawDashData[i].Username].SignedComms =
                    DashDataOb[RawDashData[i].Username].SignedComms +
                    RawDashData[i].EstimatedAnnualComms;

                GrandTotalCommsSigned =
                    GrandTotalCommsSigned + RawDashData[i].EstimatedAnnualComms;

                DashDataOb[RawDashData[i].Username].NumberOfQuotesSigned =
                    DashDataOb[RawDashData[i].Username].NumberOfQuotesSigned +
                    1;

                GrandTotalNumberQuotesSigned = GrandTotalNumberQuotesSigned + 1;
            }
        }

        // console.log(DashDataOb);
        var formatedArr = [];

        for (var x = 0; x < Object.keys(DashDataOb).length; x++) {
            formatedArr.push({
                name: Object.keys(DashDataOb)[x],
                TotalAQ: DashDataOb[Object.keys(DashDataOb)[x]].TotalAQ,
                TotalComms:
                    DashDataOb[Object.keys(DashDataOb)[x]].EstimatedAnnualComms,
                SignedComms: DashDataOb[Object.keys(DashDataOb)[x]].SignedComms,
                NumberOfQuotes:
                    DashDataOb[Object.keys(DashDataOb)[x]].NumberOfQuotes,
                NumberOfQuotesSigned:
                    DashDataOb[Object.keys(DashDataOb)[x]].NumberOfQuotesSigned,
            });
        }

        // console.log(formatedArr);
        this.setState({
            data: formatedArr,
            GrandTotalAQ: GrandTotalAQ,
            GrandTotalEstimatedComms: GrandTotalEstimatedComms,
            GrandTotalCommsSigned: GrandTotalCommsSigned,
            GrandTotalNumberQuotes: GrandTotalNumberQuotes,
            GrandTotalNumberQuotesSigned: GrandTotalNumberQuotesSigned,
        });
    };

    render() {
        // const PercentageTooltip = ({ active, payload, label }) => {
        //     if (active && payload && payload.length) {
        //         return (
        //             <div className="custom-tooltip">
        //                 <p className="label">{`${label} : ${payload[0].value}`}</p>
        //                 <p className="desc">
        //                     Anything you want can be displayed here.
        //                 </p>
        //             </div>
        //         );
        //     }

        //     return null;
        // };

        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-11">
                        <div className="row p-3 ">
                            <div className="quotes col-md-12  card ">
                                <div className="row tableTitle">
                                    <div className="col-md-6 ">
                                        <h2>AQ Quoted</h2>
                                    </div>

                                    <div className="col-md-6 ">
                                        <h3>
                                            Total:
                                            {this.state.GrandTotalAQ
                                                ? ' ' +
                                                  this.state.GrandTotalAQ.toLocaleString(
                                                      'en-UK'
                                                  )
                                                : 0}
                                        </h3>
                                    </div>
                                </div>
                                <div className="Graphcontainer">
                                    <ResponsiveContainer
                                        width={'100%'}
                                        height={300}
                                    >
                                        <BarChart
                                            data={this.state.data}
                                            margin={{
                                                top: 15,
                                                right: 5,
                                                bottom: 5,
                                                left: 15,
                                            }}
                                        >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="name" />
                                            <YAxis
                                                tickFormatter={(tick) => {
                                                    return tick.toLocaleString(
                                                        'en-UK'
                                                    );
                                                }}
                                            />
                                            <Tooltip
                                                formatter={(value) => {
                                                    return value.toLocaleString(
                                                        'en-UK'
                                                    );
                                                }}
                                            />
                                            <Legend
                                                wrapperStyle={{
                                                    position: 'relative',
                                                }}
                                            />
                                            <Bar
                                                dataKey="TotalAQ"
                                                fill="#496076"
                                            >
                                                <LabelList
                                                    style={{ fill: '#496076' }}
                                                    dataKey="TotalAQ"
                                                    position="top"
                                                    formatter={(value) => {
                                                        return value.toLocaleString(
                                                            'en-UK',
                                                            {
                                                                minimumFractionDigits: 0,
                                                                maximumFractionDigits: 0,
                                                            }
                                                        );
                                                    }}
                                                />
                                            </Bar>
                                            {/* <Bar dataKey="TotalComms" fill="#82ca9d" /> */}
                                        </BarChart>
                                    </ResponsiveContainer>
                                </div>
                            </div>
                        </div>

                        <div className="row p-3 ">
                            <div className="quotes col-md-12  card ">
                                <div className="row tableTitle">
                                    <div className="col-md-6">
                                        <h2>Annual Comms Quoted</h2>
                                    </div>
                                    <div className="col-md-3 ">
                                        <h3>
                                            Total:
                                            {this.state.GrandTotalEstimatedComms
                                                ? ' ' +
                                                  this.state.GrandTotalEstimatedComms.toLocaleString(
                                                      'en-UK',
                                                      {
                                                          style: 'currency',
                                                          currency: 'GBP',
                                                      }
                                                  )
                                                : 0}
                                        </h3>
                                    </div>
                                    <div className="col-md-3 ">
                                        <h3>
                                            Total Signed:
                                            {this.state.GrandTotalCommsSigned
                                                ? ' ' +
                                                  this.state.GrandTotalCommsSigned.toLocaleString(
                                                      'en-UK',
                                                      {
                                                          style: 'currency',
                                                          currency: 'GBP',
                                                      }
                                                  )
                                                : 0}
                                        </h3>
                                    </div>
                                </div>
                                <div className="Graphcontainer">
                                    <ResponsiveContainer
                                        width={'100%'}
                                        height={300}
                                    >
                                        <BarChart
                                            data={this.state.data}
                                            margin={{
                                                top: 15,
                                                right: 5,
                                                bottom: 5,
                                                left: 5,
                                            }}
                                        >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="name" />
                                            <YAxis
                                                tickFormatter={(tick) => {
                                                    return tick.toLocaleString(
                                                        'en-UK',
                                                        {
                                                            style: 'currency',
                                                            currency: 'GBP',
                                                            minimumFractionDigits: 0,
                                                            maximumFractionDigits: 0,
                                                        }
                                                    );
                                                }}
                                            />
                                            <Tooltip
                                                formatter={(value) => {
                                                    return value.toLocaleString(
                                                        'en-UK',
                                                        {
                                                            style: 'currency',
                                                            currency: 'GBP',
                                                        }
                                                    );
                                                }}
                                            />
                                            <Legend
                                                wrapperStyle={{
                                                    position: 'relative',
                                                }}
                                            />
                                            {/* <Bar dataKey="TotalAQ" fill="#8884d8" /> */}
                                            <Bar
                                                dataKey="TotalComms"
                                                fill="#82ca9d"
                                            >
                                                <LabelList
                                                    style={{ fill: 'black' }}
                                                    dataKey="TotalComms"
                                                    position="top"
                                                    formatter={(value) => {
                                                        return value.toLocaleString(
                                                            'en-UK',
                                                            {
                                                                style: 'currency',
                                                                currency: 'GBP',
                                                            }
                                                        );
                                                    }}
                                                />
                                            </Bar>
                                            <Bar
                                                dataKey="SignedComms"
                                                fill="#496076"
                                            >
                                                <LabelList
                                                    style={{ fill: 'black' }}
                                                    dataKey="SignedComms"
                                                    position="top"
                                                    formatter={(value) => {
                                                        return value.toLocaleString(
                                                            'en-UK',
                                                            {
                                                                style: 'currency',
                                                                currency: 'GBP',
                                                            }
                                                        );
                                                    }}
                                                />
                                            </Bar>
                                        </BarChart>
                                    </ResponsiveContainer>
                                </div>
                            </div>
                        </div>

                        <div className="row p-3 ">
                            <div className="quotes col-md-12  card ">
                                <div className="row tableTitle">
                                    <div className="col-md-6">
                                        <h2>Number Of Quotes</h2>
                                    </div>

                                    <div className="col-md-3 ">
                                        <h3>
                                            Total:
                                            {this.state.GrandTotalNumberQuotes
                                                ? ' ' +
                                                  this.state.GrandTotalNumberQuotes.toLocaleString(
                                                      'en-UK'
                                                  )
                                                : 0}
                                        </h3>
                                    </div>
                                    <div className="col-md-3 ">
                                        <h3>
                                            Total Signed:
                                            {this.state
                                                .GrandTotalNumberQuotesSigned
                                                ? ' ' +
                                                  this.state.GrandTotalNumberQuotesSigned.toLocaleString(
                                                      'en-UK'
                                                  )
                                                : 0}
                                        </h3>
                                    </div>
                                </div>
                                <div className="Graphcontainer">
                                    <ResponsiveContainer
                                        width={'100%'}
                                        height={300}
                                    >
                                        <BarChart
                                            data={this.state.data}
                                            margin={{
                                                top: 20,
                                                right: 5,
                                                bottom: 5,
                                                left: 5,
                                            }}
                                        >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="name" />
                                            <YAxis
                                                tickFormatter={(tick) => {
                                                    return tick.toLocaleString(
                                                        'en-UK',
                                                        {
                                                            minimumFractionDigits: 0,
                                                            maximumFractionDigits: 0,
                                                        }
                                                    );
                                                }}
                                            />
                                            <Tooltip
                                                formatter={(value) => {
                                                    return value.toLocaleString(
                                                        'en-UK'
                                                    );
                                                }}
                                                // content={<PercentageTooltip />}
                                            />
                                            <Legend
                                                wrapperStyle={{
                                                    position: 'relative',
                                                }}
                                            />
                                            {/* <Bar dataKey="TotalAQ" fill="#8884d8" /> */}
                                            <Bar
                                                dataKey="NumberOfQuotes"
                                                fill="#82ca9d"
                                            >
                                                <LabelList
                                                    style={{ fill: 'black' }}
                                                    dataKey="NumberOfQuotes"
                                                    position="top"
                                                    formatter={(value) => {
                                                        return value.toLocaleString(
                                                            'en-UK'
                                                        );
                                                    }}
                                                />
                                            </Bar>
                                            <Bar
                                                dataKey="NumberOfQuotesSigned"
                                                fill="#496076"
                                            >
                                                <LabelList
                                                    style={{ fill: 'black' }}
                                                    dataKey="NumberOfQuotesSigned"
                                                    position="top"
                                                    formatter={(value) => {
                                                        return value.toLocaleString(
                                                            'en-UK'
                                                        );
                                                    }}
                                                />
                                            </Bar>
                                        </BarChart>
                                    </ResponsiveContainer>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className=" col-12 col-md-6 col-xl-1">
                        <div className="row">
                            <div className="col-12">
                                <label className="h6" htmlFor="PeriodFrom">
                                    Period From
                                </label>
                                <input
                                    autoComplete="off"
                                    className="form-control align-middle "
                                    type="text"
                                    id="PeriodFrom"
                                    name="PeriodFrom"
                                    onChange={this.handleInputChange}
                                    placeholder="Period From"
                                    value={this.state.PeriodFrom}
                                ></input>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <label className="h6" htmlFor="PeriodTo">
                                    Period To
                                </label>
                                <input
                                    autoComplete="off"
                                    className="form-control align-middle "
                                    type="text"
                                    id="PeriodTo"
                                    name="PeriodTo"
                                    onChange={this.handleInputChange}
                                    placeholder="Period To"
                                    value={this.state.PeriodTo}
                                ></input>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <button
                                    className="btn btn-primary mt-3"
                                    id="ButtonRefresh"
                                    onClick={this.RefreshTheData}
                                >
                                    Refresh
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default DashboardParent;
