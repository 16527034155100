import { Apibase } from './Apibase';

const refreshTokenFetch = async function (url, init) {
    var accessExpiry = localStorage.getItem('accessTokenExp');

    if (
        // Token doesnt need refreshing just run the fetch
        accessExpiry > Date.now() &&
        localStorage.getItem('refreshing') === 'false'
    ) {
        init.headers.authorization =
            'Bearer ' + localStorage.getItem('accessToken');
        return fetch(url, init);
    } else if (localStorage.getItem('refreshing') === 'false') {
        //Token Needs refreshing but isnt yet
        localStorage.setItem('refreshing', 'true');
    } else {
        // Token is already being refreshed so wait for it to finish
        var x = 0;

        function delay(ms) {
            return new Promise((resolve) => setTimeout(resolve, ms));
        }

        async function loop() {
            while (localStorage.getItem('refreshing') === 'true' && x < 5000) {
                x = x + 1;

                await delay(1);
            }

            if (x < 5000) {
                return true;
            } else {
                return false;
            }
        }

        var waitForRefresh = await loop();

        if (waitForRefresh) {
            init.headers.authorization =
                'Bearer ' + localStorage.getItem('accessToken');
            return fetch(url, init);
        }
    }

    var tokenrefreshed = false;

    tokenrefreshed = await refreshFetch();

    if (tokenrefreshed) {
        init.headers.authorization = 'Bearer ' + tokenrefreshed;
        //console.log(init)
        return fetch(url, init);
    }
};

const refreshFetch = async function () {
    const TOKEN_REFRESH_URL = Apibase + '/api/users/refreshtoken';

    return new Promise(async function (resolve, reject) {
        await fetch(TOKEN_REFRESH_URL, {
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'POST',
            body: JSON.stringify({
                refreshToken: localStorage.getItem('refreshToken'),
            }),
        })
            .then((res) => res.json())
            .then(
                (result) => {
                    localStorage.setItem('accessToken', result.AccessToken);
                    localStorage.setItem('accessTokenExp', Date.now() + 720000);
                    localStorage.setItem('refreshToken', result.RefreshToken);
                    localStorage.setItem('refreshing', 'false');

                    resolve(result.AccessToken);
                },
                (error) => {
                    localStorage.setItem('refreshing', 'false');
                    localStorage.setItem('loggedin', false);
                    localStorage.setItem('accessToken', '');
                    localStorage.setItem('accessTokenExp', '');
                    localStorage.setItem('refreshToken', '');

                    reject(false);

                    window.location.href = '/login';
                }
            );
    });
};

export default refreshTokenFetch;
