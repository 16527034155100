import React from 'react';
import { Apibase } from './Apibase';
import fetch from './refreshTokenFetch';

import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/button';
import Spinner from 'react-bootstrap/Spinner';
import { download_table_as_csv } from './TableToExcel';
import ViewAllPricesModalForm from './ViewAllPricesModalFrm';

class ModalChangeQuoteStatusForm extends React.Component {
    state = { NewquoteStatus: '' };

    handleChange = (e) => {
        this.setState({ NewquoteStatus: e.target.value });
    };

    render() {
        return (
            <Modal
                show={this.props.isQuoteStatusOpen}
                onHide={this.props.closeQuoteStatusModal}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Please Select A Quote Status</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>
                            Please select a quote status for quote number:{' '}
                            {this.props.selectedQuote}
                        </Form.Label>

                        <Form.Control as="select" onChange={this.handleChange}>
                            <option default hidden>
                                Please Choose...
                            </option>
                            <option>Requesting Prices</option>
                            <option>Prices Requested</option>
                            <option>Prices Received</option>
                            <option>Quote Sent</option>
                            <option>Requesting Contracts</option>
                            <option>Contracts Sent</option>
                            <option>Customer Signed Contract</option>
                            <option>Submitted For Lock</option>
                            <option>Locked</option>
                            <option>Live</option>
                            <option>Lost</option>
                            <option>Cancelled</option>
                        </Form.Control>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        type="submit"
                        onClick={() =>
                            this.props.handleQuoteStatusSubmit(
                                this.state.NewquoteStatus
                            )
                        }
                    >
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

class ModalForm extends React.Component {
    state = { NewquoteStatus: '' };

    handleChange = (e) => {
        this.setState({ NewquoteStatus: e.target.value });
    };

    render() {
        return (
            <Modal show={this.props.isOpen} onHide={this.props.closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Please Select Comms</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>Please Enter Desired Commission</Form.Label>

                        <Form.Control
                            type="text"
                            placeholder="Pence Per kWh"
                            onChange={(e) =>
                                this.setState({ PPKWH: e.target.value })
                            }
                        />
                        <Form.Control
                            type="text"
                            placeholder="Standing Charge £/Annum"
                            onChange={(e) =>
                                this.setState({ SCComms: e.target.value })
                            }
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        type="submit"
                        onClick={() =>
                            this.props.handleSubmit(
                                this.state.PPKWH,
                                this.state.SCComms
                            )
                        }
                    >
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

class ModalFormSettingAcceptedPriceVars extends React.Component {
    state = {};

    render() {
        return (
            <Modal
                show={this.props.isOpen}
                onHide={this.props.closeModal}
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Please Select Accepted Price Options
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>IV Included:</Form.Label>

                        <Form.Control
                            as="select"
                            onChange={(e) =>
                                this.setState({ IVInc: e.target.value })
                            }
                        >
                            <option default hidden>
                                Please Choose...
                            </option>
                            <option>Yes</option>
                            <option>No</option>
                        </Form.Control>
                        <br />
                        <Form.Label>IV Annual Cost:</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="IV Annual Cost"
                            onChange={(e) =>
                                this.setState({ IVAnnCost: e.target.value })
                            }
                        />
                        <br />

                        <Form.Label>Account Management Included:</Form.Label>

                        <Form.Control
                            as="select"
                            onChange={(e) =>
                                this.setState({ AMInc: e.target.value })
                            }
                        >
                            <option default hidden>
                                Please Choose...
                            </option>
                            <option>Yes</option>
                            <option>No</option>
                        </Form.Control>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        type="submit"
                        onClick={() =>
                            this.props.handleSubmit(
                                this.props.SelectedPrice,
                                this.state.IVInc,
                                this.state.IVAnnCost,
                                this.state.AMInc
                            )
                        }
                    >
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

class SelectPricesToInclude extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            completedFilter: false,
            awaitingPricesFilter: true,
            pricesSummary: [],

            priceSelected: null,
            priceDetail: false,
            isOpen: false,
            showAll: false,
            metersToShow: false,
            allmeters: false,
            metersnotinc: false,
            GotUDPrices: false,
            DisplaySpinner: true,
            DisplaySalesforceUploadTable: false,
            isAccPriceOptions: false,
            selectedPriceAccepted: false,
            IncludePricesWithoutallMeters: false,
        };
    }

    componentDidMount() {
        this.getPrices(this.props.match.params.id);
        this.getQuotes(this.props.match.params.id);
    }

    handleaccetedpriceoptions = (PriceID, IVInc, IVCost, AMInc) => {
        //console.log(IVCost);

        if (
            Number.isFinite(+IVCost) &&
            (IVInc === 'Yes' || IVInc === 'No') &&
            (AMInc === 'Yes' || AMInc === 'No')
        ) {
            fetch(Apibase + '/api/v1/prices/MakeAcceptedWithParams', {
                headers: { 'Content-Type': 'application/json' },
                method: 'POST',
                body: JSON.stringify({
                    priceGroupID: PriceID,
                    quoteid: this.props.match.params.id,
                    accPrice: 1,
                    IVIncluded: IVInc === 'Yes' ? 1 : 0,
                    AMIncluded: AMInc === 'Yes' ? 1 : 0,
                    IVAnnualCost: IVCost ? IVCost : 0,
                }),
            }).then(() => {
                this.getPrices(this.props.match.params.id);
                this.closeAccPriceOptions();
                this.priceClicked(PriceID);
            });
        } else {
            alert(
                'Numbers only in annual IV Cost And You Must Select IV Included And Account Management'
            );
        }
    };

    openAccPriceOptions = () => {
        //console.log(this.state.isQuoteStatusOpen);
        this.setState({ isAccPriceOptions: true });
    };
    closeAccPriceOptions = () => this.setState({ isAccPriceOptions: false });

    handleQuoteStatusSubmit = (quoteStatus) => {
        fetch(Apibase + '/api/v1/quotes/ChangeStatus', {
            headers: { 'Content-Type': 'application/json' },
            method: 'POST',
            body: JSON.stringify({
                NewStatus: quoteStatus,
                QuoteID: this.props.match.params.id,
            }),
        }).then(() => {
            this.getPrices(this.props.match.params.id);
            this.closeQuoteStatusModal();
            this.setState({ QuoteStatus: quoteStatus });
        });
    };

    getPrices(QuoteID) {
        fetch(Apibase + '/api/v1/prices/byQuoteWithComp/' + QuoteID, {
            headers: {},
            method: 'GET',
        })
            .then((res) => res.json())
            .then(
                (result) => {
                    this.setState({
                        pricesSummary: result,
                    });

                    this.loadSpinner(false);
                    //console.log('hit here third')
                },

                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        isLoaded: false,
                        error,
                    });
                }
            );
    }

    getQuotes(QuoteID) {
        fetch(Apibase + '/api/v1/quotes/specific/' + QuoteID, {
            headers: {},
            method: 'GET',
        })
            .then((res) => res.json())
            .then(
                (result) => {
                    this.setState({
                        quotedetail: result,
                        QuoteStatus:
                            result.length > 0 ? result[0].QuoteStatus : '',
                        requestedppkwh:
                            result.length > 0
                                ? result[0].RequestedPPKWHComms
                                : '',
                        requestedSC:
                            result.length > 0
                                ? result[0].RequestedStandingChargeComms
                                : '',
                        QuoteCustName: result[0].CustomerName,
                        QuoteNotes: result[0].Notes,
                        numberOfMetersInQuote: result[0].NumberOfMeters,
                    });

                    this.loadSpinner(false);
                    //console.log('hit here third')
                },

                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        isLoaded: false,
                        error,
                    });
                }
            );
    }

    openModal = () => {
        if (this.state.quoteSelected === null) {
            alert('Please select a quote first!');
        } else {
            this.setState({ isOpen: true });
        }
    };
    closeModal = () => this.setState({ isOpen: false });

    openQuoteStatusModal = () => {
        //console.log(this.state.isQuoteStatusOpen);
        this.setState({ isQuoteStatusOpen: true });
    };
    closeQuoteStatusModal = () => this.setState({ isQuoteStatusOpen: false });
    handleSubmit = (PPKWH, SCComms) => {
        this.loadSpinner(true);
        if (PPKWH == null) PPKWH = 0;
        if (SCComms == null) SCComms = 0;

        if (Number.isFinite(+PPKWH) && Number.isFinite(+SCComms)) {
            var commsdetail = JSON.stringify({
                quoteid: this.props.match.params.id,
                UnitRateUplift: PPKWH,
                StandingChargeUplift: SCComms,
            });

            fetch(Apibase + '/api/v1/prices/getMatrixPrices', {
                headers: { 'Content-Type': 'application/json' },
                method: 'POST',
                body: commsdetail,
            }).then(() => {
                this.getPrices(this.props.match.params.id);
            });
            this.closeModal();
        } else {
            alert('Only Enter Numbers Please');
        }
    };

    loadSpinner = (DisplaySpinner) => {
        this.setState({ DisplaySpinner: DisplaySpinner });
    };

    openCustQuote = () => {
        this.props.history.push('/viewQuote/' + this.props.match.params.id);
    };

    showall(showallmeters) {
        if (showallmeters === true) {
            this.setState({ showAll: true });
            this.setState({ metersToShow: this.state.allmeters });
        } else {
            this.setState({ showAll: false });
            this.setState({ metersToShow: this.state.metersnotinc });
        }
    }

    priceClicked(priceGroupID) {
        this.setState({ quoteSelected: priceGroupID });
        // console.log('event firing');
        fetch(Apibase + '/api/v1/prices/detailedbyPG/' + priceGroupID, {
            headers: {},
            method: 'GET',
        })
            .then((res) => res.json())
            .then(
                (result) => {
                    this.setState({
                        priceDetail: result,
                        selectedPriceAccepted: result[0].PriceGroupAccepted
                            ? true
                            : false,
                    });

                    if (!result[0].PriceGroupAccepted) {
                        this.ShowSalesforcetable(false);
                    }

                    //console.log('hit here third')
                },

                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        isLoaded: false,
                        error,
                    });
                }
            );

        var QuoteID = this.props.match.params.id;
        var newallmeters = null;

        fetch(
            Apibase +
                '/api/v1/meters/byQuoteAndPriceGroup/' +
                QuoteID +
                '/' +
                priceGroupID,
            {
                headers: {},
                method: 'GET',
            }
        )
            .then((res) => res.json())
            .then(
                (result) => {
                    newallmeters = result;
                    this.setState({
                        allmeters: result,
                    });
                    var newmetersnotinc = [],
                        i;

                    for (i = 0; i < result.length; i++) {
                        if (result[i].IsInGroup !== 1) {
                            newmetersnotinc.push(result[i]);
                        }
                    }

                    this.setState({
                        metersnotinc: newmetersnotinc,
                    });

                    this.state.showAll
                        ? this.setState({ metersToShow: newallmeters })
                        : this.setState({ metersToShow: newmetersnotinc });
                    //console.log(newallmeters);
                },

                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        isLoaded: false,
                        error,
                    });
                }
            );
    }

    searchquotes() {
        var input, filter, table, tr, i, txtValue, currtr;
        input = document.getElementById('myInput');
        filter = input.value.toUpperCase();
        table = document.getElementById('myTable');
        tr = table.getElementsByTagName('tr');

        for (i = 0; i < tr.length; i++) {
            currtr = tr[i];
            if (currtr) {
                txtValue = currtr.textContent || currtr.innerText;
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                    tr[i].style.display = '';
                } else {
                    tr[i].style.display = 'none';
                }
            }
        }
    }

    deleteMatrixPrices() {
        fetch(
            Apibase +
                '/api/v1/prices/removeUDPrices/' +
                this.props.match.params.id,
            {
                headers: {},
                method: 'GET',
            }
        ).then(() => {
            this.getPrices(this.props.match.params.id);

            this.setState({
                priceDetail: false,
                metersToShow: false,
                allmeters: false,
                metersnotinc: false,
                selectedPriceAccepted: false,
            });
        });
    }

    deleteSelectedPrices() {
        if (!this.state.quoteSelected) {
            return alert('Please select a price first');
        }
        fetch(
            Apibase +
                '/api/v1/prices/removeSelectedPrices/' +
                this.state.quoteSelected,
            {
                headers: {},
                method: 'GET',
            }
        ).then(() => {
            this.getPrices(this.props.match.params.id);

            this.setState({
                priceDetail: false,
                metersToShow: false,
                allmeters: false,
                metersnotinc: false,
                selectedPriceAccepted: false,
                quoteSelected: null,
            });
        });
    }

    deleteAllBespokePrices() {
        fetch(
            Apibase +
                '/api/v1/prices/removeAllBespokePrices/' +
                this.props.match.params.id,
            {
                headers: {},
                method: 'GET',
            }
        ).then(() => {
            this.getPrices(this.props.match.params.id);

            this.setState({
                priceDetail: false,
                metersToShow: false,
                allmeters: false,
                metersnotinc: false,
                selectedPriceAccepted: false,
                quoteSelected: null,
            });
        });
    }

    formatLeadingzeros = (num, len) => {
        var formatedval = num.toString();
        for (var i = num.toString().length; i < len; i++) {
            formatedval = '0' + formatedval;
        }

        return formatedval;
    };

    makeInclude(PriceGroupID, val) {
        fetch(
            Apibase + '/api/v1/prices/MakeInclude/' + PriceGroupID + '/' + val,
            {
                headers: {},
                method: 'GET',
            }
        ).then(() => this.getPrices(this.props.match.params.id));
    }

    makeRecommend(PriceGroupID, val) {
        fetch(
            Apibase +
                '/api/v1/prices/MakeRecommend/' +
                this.props.match.params.id +
                '/' +
                PriceGroupID +
                '/' +
                val,
            {
                headers: {},
                method: 'GET',
            }
        ).then(() => this.getPrices(this.props.match.params.id));
    }

    makeAccepted(PriceGroupID, val) {
        if (val === 1) {
            this.setState({ isAccPriceOptions: true });
        } else {
            fetch(
                Apibase +
                    '/api/v1/prices/MakeAccepted/' +
                    this.props.match.params.id +
                    '/' +
                    PriceGroupID +
                    '/' +
                    val,
                {
                    headers: {},
                    method: 'GET',
                }
            ).then(() => {
                this.getPrices(this.props.match.params.id);

                this.priceClicked(PriceGroupID);
            });
        }
    }

    ShowSalesforcetable(shouldshow) {
        this.setState({ DisplaySalesforceUploadTable: shouldshow });
    }

    openViewAllPrices = () => this.setState({ ViewAllPrices: true });

    closeViewAllPrices = () => this.setState({ ViewAllPrices: false });

    render() {
        return (
            <div className="container-fluid">
                {this.state.isOpen ? (
                    <ModalForm
                        closeModal={this.closeModal}
                        isOpen={this.state.isOpen}
                        handleSubmit={this.handleSubmit}
                        selectedQuote={this.state.quoteSelected}
                    />
                ) : null}

                {this.state.isQuoteStatusOpen ? (
                    <ModalChangeQuoteStatusForm
                        closeQuoteStatusModal={this.closeQuoteStatusModal}
                        isQuoteStatusOpen={this.state.isQuoteStatusOpen}
                        handleQuoteStatusSubmit={this.handleQuoteStatusSubmit}
                        selectedQuote={this.props.match.params.id}
                    />
                ) : null}

                {this.state.isAccPriceOptions ? (
                    <ModalFormSettingAcceptedPriceVars
                        closeModal={this.closeAccPriceOptions}
                        isOpen={this.state.isAccPriceOptions}
                        handleSubmit={this.handleaccetedpriceoptions}
                        SelectedPrice={this.state.quoteSelected}
                    />
                ) : null}

                {this.state.ViewAllPrices ? (
                    <ViewAllPricesModalForm
                        isOpen={this.state.ViewAllPrices}
                        QuoteID={this.props.match.params.id}
                        closeModal={this.closeViewAllPrices}
                    />
                ) : null}

                <div className="quotelistMain">
                    <div className="quotelistHeader row">
                        <div className="col-md-5">
                            <h1>Select Prices To Include</h1>
                            <div className="row">
                                <div className="col-md-3">
                                    <h4>Filter Prices:</h4>
                                </div>
                                <div className="col-md-7 ">
                                    <input
                                        autoComplete="off"
                                        className="form-control align-middle "
                                        type="text"
                                        id="myInput"
                                        onKeyUp={() => this.searchquotes()}
                                        placeholder="Search for prices..."
                                    ></input>
                                </div>
                                <div className="col-md-2 mx-auto">
                                    {this.state.DisplaySpinner ? (
                                        <Spinner
                                            animation="border"
                                            role="status"
                                        >
                                            <span className="sr-only">
                                                Loading...
                                            </span>
                                        </Spinner>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-7">
                            <h4>Quote Status: {this.state.QuoteStatus}</h4>
                            <h4>Customer Name: {this.state.QuoteCustName}</h4>
                            <h4>
                                Requested Comms:{' '}
                                {this.state.requestedppkwh
                                    ? this.state.requestedppkwh + ' PPkWh '
                                    : ''}{' '}
                                {this.state.requestedSC
                                    ? this.state.requestedSC + ' PMPA'
                                    : ''}
                            </h4>
                            <h4>Notes: {this.state.QuoteNotes}</h4>
                        </div>
                        <br></br>
                    </div>
                    <div className="row p-3">
                        <div className="quotes col-md-6 mb-4 mb-md-0 card">
                            <div className="row tableTitle">
                                <div className="col-md-6">
                                    <h2>Prices</h2>
                                </div>
                                <div className="col-md-3">
                                    <input
                                        className="form-check-input mt-3"
                                        type="checkbox"
                                        id="PricesWithoutMeters"
                                        defaultChecked={
                                            this.state
                                                .IncludePricesWithoutallMeters
                                        }
                                        onChange={() =>
                                            this.setState({
                                                IncludePricesWithoutallMeters:
                                                    !this.state
                                                        .IncludePricesWithoutallMeters,
                                            })
                                        }
                                    ></input>

                                    <label
                                        className="form-check-label"
                                        for="PricesWithoutMeters"
                                    >
                                        Show Prices without All Meters
                                    </label>
                                </div>

                                <div className="col-md-3">
                                    <button
                                        type="button"
                                        className="btn btn-link"
                                        href="#"
                                        onClick={() =>
                                            download_table_as_csv('pricesTable')
                                        }
                                    >
                                        Download as CSV
                                    </button>
                                </div>
                            </div>

                            <div className="table-responsive quotesTable mb-3 smtext">
                                <table
                                    id="pricesTable"
                                    className="table  table-bordered table-striped table-hover "
                                >
                                    <thead>
                                        <tr>
                                            <th>#</th>

                                            <th>Price Name</th>
                                            <th>Total Annual Cost</th>
                                            <th>Saving/ Increase</th>
                                            <th>Valid Until</th>
                                            <th>Include</th>
                                            <th>Recommend</th>
                                            <th>Accepted</th>
                                            <th>Total Commission per annum</th>
                                            <th>No. Meters</th>
                                        </tr>
                                    </thead>
                                    <tbody id="myTable" className="">
                                        {this.state.pricesSummary.map(
                                            (item, key) => {
                                                if (
                                                    this.state
                                                        .numberOfMetersInQuote ===
                                                        item.NumberOFMetersInc ||
                                                    this.state
                                                        .IncludePricesWithoutallMeters
                                                ) {
                                                    return (
                                                        <tr
                                                            key={key}
                                                            className={
                                                                item.PriceGroupIDPK ===
                                                                this.state
                                                                    .quoteSelected
                                                                    ? 'table-primary'
                                                                    : ''
                                                            }
                                                            onClick={() =>
                                                                this.priceClicked(
                                                                    item.PriceGroupIDPK
                                                                )
                                                            }
                                                        >
                                                            <td>
                                                                {
                                                                    item.PriceGroupIDPK
                                                                }
                                                            </td>

                                                            <td>
                                                                {item.PriceName.replace(
                                                                    /~/g,
                                                                    ''
                                                                )}
                                                            </td>
                                                            <td>
                                                                {item.TotalAnnualCost
                                                                    ? item.TotalAnnualCost.toLocaleString(
                                                                          'en-UK',
                                                                          {
                                                                              style: 'currency',
                                                                              currency:
                                                                                  'GBP',
                                                                          }
                                                                      )
                                                                    : 0}
                                                            </td>
                                                            <td
                                                                className={
                                                                    item.CostDiff >=
                                                                    0
                                                                        ? 'text-success'
                                                                        : 'text-danger'
                                                                }
                                                            >
                                                                {item.CostDiff
                                                                    ? item.CostDiff.toLocaleString(
                                                                          'en-UK',
                                                                          {
                                                                              style: 'currency',
                                                                              currency:
                                                                                  'GBP',
                                                                          }
                                                                      )
                                                                    : 0}
                                                            </td>
                                                            <td>
                                                                {
                                                                    item.ValidUntil
                                                                }
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="checkbox"
                                                                    name="include"
                                                                    checked={
                                                                        item.Include
                                                                    }
                                                                    onChange={() => {
                                                                        this.makeInclude(
                                                                            item.PriceGroupIDPK,
                                                                            item.Include ===
                                                                                0
                                                                                ? 1
                                                                                : 0
                                                                        );
                                                                    }}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="checkbox"
                                                                    name="Reccomend"
                                                                    checked={
                                                                        item.Recommend
                                                                    }
                                                                    onChange={() => {
                                                                        this.makeRecommend(
                                                                            item.PriceGroupIDPK,
                                                                            item.Recommend ===
                                                                                0
                                                                                ? 1
                                                                                : 0
                                                                        );
                                                                    }}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="checkbox"
                                                                    name="Accepted"
                                                                    checked={
                                                                        item.PriceGroupAccepted
                                                                    }
                                                                    onChange={() => {
                                                                        this.makeAccepted(
                                                                            item.PriceGroupIDPK,
                                                                            item.PriceGroupAccepted ===
                                                                                0
                                                                                ? 1
                                                                                : 0
                                                                        );
                                                                    }}
                                                                />
                                                            </td>
                                                            <td>
                                                                {item.TotalAnnualCommission
                                                                    ? item.TotalAnnualCommission.toLocaleString(
                                                                          'en-UK',
                                                                          {
                                                                              style: 'currency',
                                                                              currency:
                                                                                  'GBP',
                                                                          }
                                                                      )
                                                                    : 0}
                                                            </td>
                                                            <td>
                                                                {
                                                                    item.NumberOFMetersInc
                                                                }
                                                            </td>
                                                        </tr>
                                                    );
                                                } else {
                                                    return null;
                                                }
                                            }
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="metersandprices col-md-6">
                            <div className="card  p-3 mb-4">
                                <div className="row tableTitle">
                                    <div className="col-md-3">
                                        <h2>Price Detail</h2>
                                    </div>

                                    <div className="col-md-3">
                                        <button
                                            type="button"
                                            className="btn btn-link"
                                            href="#"
                                            onClick={() =>
                                                download_table_as_csv(
                                                    'pricesDetailTable'
                                                )
                                            }
                                        >
                                            Download as CSV
                                        </button>
                                    </div>
                                    <div className="col-6">
                                        <ul className="nav nav-pills">
                                            <li className="nav-item">
                                                <button
                                                    type="button"
                                                    className={
                                                        this.state
                                                            .DisplaySalesforceUploadTable
                                                            ? 'btn btn-light mr-1'
                                                            : 'btn btn-primary mr-1'
                                                    }
                                                    onClick={() =>
                                                        this.ShowSalesforcetable(
                                                            false
                                                        )
                                                    }
                                                >
                                                    Detail
                                                </button>
                                            </li>
                                            <li className="nav-item">
                                                {this.state
                                                    .selectedPriceAccepted ? (
                                                    <button
                                                        type="button"
                                                        className={
                                                            this.state
                                                                .DisplaySalesforceUploadTable
                                                                ? 'btn btn-primary'
                                                                : 'btn btn-light'
                                                        }
                                                        onClick={() =>
                                                            this.ShowSalesforcetable(
                                                                true
                                                            )
                                                        }
                                                    >
                                                        Salesforce Format
                                                    </button>
                                                ) : null}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="table-responsive metersTablespi">
                                    {this.state.DisplaySalesforceUploadTable ? (
                                        <table
                                            id="pricesDetailTable"
                                            className="table table-bordered table-sm"
                                        >
                                            <thead>
                                                <tr>
                                                    <th>Opportunity ID</th>
                                                    <th>Account ID</th>
                                                    <th>Contract Type</th>
                                                    <th>Name</th>
                                                    <th>Supplier</th>
                                                    <th>Contract Owner</th>
                                                    <th>Sales Agent</th>
                                                    <th>Shared Sales Agent</th>
                                                    <th>MPAN/MPR</th>
                                                    <th>
                                                        Date Signed (or
                                                        estimated)
                                                    </th>
                                                    <th>
                                                        Contract Record Type
                                                    </th>
                                                    <th>Sales Stage</th>
                                                    <th>Contract Start Date</th>
                                                    <th>Contract End Date</th>
                                                    <th>Pricing Type</th>
                                                    <th>UA Commission</th>
                                                    <th>UA Commission Term</th>
                                                    <th>UA Commission 2</th>
                                                    <th>
                                                        UA Commission 2 Term
                                                    </th>
                                                    <th>IV Validation PMPY</th>
                                                    <th>IV Validation ppkwh</th>
                                                    <th>
                                                        Total Contract
                                                        Commission
                                                    </th>
                                                    <th>Meter Type</th>
                                                    <th>AMR Provider</th>
                                                    <th>AMR agreement with</th>
                                                    <th>IV</th>
                                                    <th>Day AQ</th>
                                                    <th>Night AQ</th>
                                                    <th>Other AQ</th>
                                                    <th>
                                                        Winning Annual Spnd
                                                        (+Incr/-Decr)
                                                    </th>
                                                    <th>Service Charge</th>
                                                    <th>S/C Frequency</th>
                                                    <th>Day Rate</th>
                                                    <th>Night Rate</th>
                                                    <th>Other Rate</th>
                                                    <th>FiTS (ppkWh)</th>
                                                    <th>
                                                        Other Charges, £ per
                                                        annum
                                                    </th>
                                                    <th>KVA, £ per annum</th>
                                                    <th>
                                                        Estimated Annual Spend
                                                    </th>
                                                    <th>Deal Sheet</th>
                                                    <th>Sent for lock</th>
                                                    <th>Contract Locked</th>
                                                    <th>Failed Contract</th>
                                                    <th>Live</th>
                                                    <th>NCT Contract</th>
                                                    <th>Registration Stage</th>
                                                    <th>IV Annual Cost</th>
                                                    <th>Account Managed</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.priceDetail ? (
                                                    this.state.priceDetail.map(
                                                        (item, key) => {
                                                            return (
                                                                <tr key={key}>
                                                                    <td>
                                                                        {
                                                                            item.SalesforceOpportunityID
                                                                        }
                                                                    </td>
                                                                    <td></td>
                                                                    <td>
                                                                        {
                                                                            item.ContractType
                                                                        }
                                                                    </td>
                                                                    <td></td>
                                                                    <td>
                                                                        {
                                                                            item.SupplierSalesforceCode
                                                                        }
                                                                    </td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td>
                                                                        {
                                                                            item.MeterNumber
                                                                        }
                                                                    </td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td>
                                                                        {
                                                                            item.StartDate
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.EndDate
                                                                        }
                                                                    </td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td>
                                                                        {item.IVIncluded
                                                                            ? 'Yes'
                                                                            : 'No'}
                                                                    </td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td>
                                                                        {
                                                                            item.StandingCharge
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.StandingChargeFrequencySalesforce
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.DayRate
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.NightRate
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.OtherRate
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.FITS
                                                                        }
                                                                    </td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td>
                                                                        {item.IVAnnualCost
                                                                            ? item.IVAnnualCost
                                                                            : 0}
                                                                    </td>
                                                                    <td>
                                                                        {item.AccountManaged
                                                                            ? 'Yes'
                                                                            : 'No'}
                                                                    </td>
                                                                </tr>
                                                            );
                                                        }
                                                    )
                                                ) : (
                                                    <tr></tr>
                                                )}
                                            </tbody>
                                        </table>
                                    ) : (
                                        <table
                                            id="pricesDetailTable"
                                            className="table table-bordered table-sm"
                                        >
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Meter Number</th>
                                                    <th>Day Rate</th>
                                                    <th>Night Rate</th>
                                                    <th>Other Rate</th>
                                                    <th>FITS</th>
                                                    <th>Standing Charge</th>
                                                    <th>
                                                        Standing Charge
                                                        Frequency
                                                    </th>
                                                    <th>Cost Per KVA</th>
                                                    <th>KVA Per Month</th>
                                                    <th>Annual Cost</th>
                                                    <th>Saving/Increase</th>
                                                    <th>Comms PMPA</th>
                                                    <th>Comms PPKWA</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.priceDetail ? (
                                                    this.state.priceDetail.map(
                                                        (item, key) => {
                                                            return (
                                                                <tr key={key}>
                                                                    <td>
                                                                        {
                                                                            item.PriceIDPK
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.MeterNumber
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.DayRate
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.NightRate
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.OtherRate
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.FITS
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.StandingCharge
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.StandingChargeFrequency
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.CostPerKVA
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.KVAPerMonth
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {item.AnnualCost
                                                                            ? item.AnnualCost.toLocaleString(
                                                                                  'en-UK',
                                                                                  {
                                                                                      style: 'currency',
                                                                                      currency:
                                                                                          'GBP',
                                                                                  }
                                                                              )
                                                                            : 0}
                                                                    </td>
                                                                    <td
                                                                        className={
                                                                            item.CostDiff >=
                                                                            0
                                                                                ? 'text-success'
                                                                                : 'text-danger'
                                                                        }
                                                                    >
                                                                        {item.CostDiff
                                                                            ? item.CostDiff.toLocaleString(
                                                                                  'en-UK',
                                                                                  {
                                                                                      style: 'currency',
                                                                                      currency:
                                                                                          'GBP',
                                                                                  }
                                                                              )
                                                                            : 0}
                                                                    </td>
                                                                    <td>
                                                                        {item.SCComms
                                                                            ? item.SCComms.toLocaleString(
                                                                                  'en-UK',
                                                                                  {
                                                                                      style: 'currency',
                                                                                      currency:
                                                                                          'GBP',
                                                                                  }
                                                                              )
                                                                            : 0}
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.PPKWHComms
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            );
                                                        }
                                                    )
                                                ) : (
                                                    <tr></tr>
                                                )}
                                            </tbody>
                                        </table>
                                    )}
                                </div>
                            </div>
                            <div className="card  p-3 ">
                                <div className="row">
                                    <div className="col-3">
                                        <h2>Meters</h2>
                                    </div>
                                    <div className="col-3">
                                        <button
                                            type="button"
                                            className="btn btn-link"
                                            href="#"
                                            onClick={() =>
                                                download_table_as_csv(
                                                    'metersdetailTable'
                                                )
                                            }
                                        >
                                            Download as CSV
                                        </button>
                                    </div>
                                    <div className="col-6">
                                        <ul className="nav nav-pills">
                                            <li className="nav-item">
                                                <button
                                                    type="button"
                                                    className={
                                                        this.state.showAll
                                                            ? 'btn btn-light mr-1'
                                                            : 'btn btn-primary mr-1'
                                                    }
                                                    onClick={() =>
                                                        this.showall(false)
                                                    }
                                                >
                                                    Meters Not Included
                                                </button>
                                            </li>
                                            <li className="nav-item">
                                                <button
                                                    type="button"
                                                    className={
                                                        this.state.showAll
                                                            ? 'btn btn-primary'
                                                            : 'btn btn-light'
                                                    }
                                                    onClick={() =>
                                                        this.showall(true)
                                                    }
                                                >
                                                    All Meters
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <h4>
                                    {this.state.showAll
                                        ? 'All Meters'
                                        : 'Meters not included'}
                                </h4>
                                <div className="table-responsive metersTablespi">
                                    <table
                                        id="metersdetailTable"
                                        className="table table-bordered table-sm"
                                    >
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Top Line</th>
                                                <th>Meter Number</th>
                                                <th>In Contract Until</th>
                                                <th>AQ Day</th>
                                                <th>AQ Night</th>
                                                <th>AQ Other</th>
                                                <th>Included?</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.metersToShow ? (
                                                this.state.metersToShow.map(
                                                    (item, key) => {
                                                        return (
                                                            <tr key={key}>
                                                                <td>
                                                                    {
                                                                        item.MeterIDPK
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {item
                                                                        .MeterNumber
                                                                        .length ===
                                                                    13
                                                                        ? this.formatLeadingzeros(
                                                                              item.ProfileClass,
                                                                              2
                                                                          ) +
                                                                          this.formatLeadingzeros(
                                                                              item.MTC,
                                                                              3
                                                                          ) +
                                                                          this.formatLeadingzeros(
                                                                              item.LLF,
                                                                              3
                                                                          )
                                                                        : ''}
                                                                </td>
                                                                <td>
                                                                    {
                                                                        item.MeterNumber
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {
                                                                        item.ContractEnd
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {item.DayAQ}
                                                                </td>
                                                                <td>
                                                                    {
                                                                        item.NightAQ
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {
                                                                        item.OtherAQ
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {item.IsInGroup
                                                                        ? 'Yes'
                                                                        : 'No'}
                                                                </td>
                                                            </tr>
                                                        );
                                                    }
                                                )
                                            ) : (
                                                <tr></tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row  no-gutters">
                        <div className="col-md-7  d-flex ">
                            <div className="col-md-3 ">
                                <button
                                    type="button"
                                    className="btn btn-danger"
                                    onClick={() => this.deleteMatrixPrices()}
                                >
                                    Delete Matrix Prices
                                </button>
                            </div>
                            <div className="col-md-3  ">
                                <button
                                    type="button"
                                    onClick={this.openModal}
                                    className="btn btn-secondary"
                                >
                                    Get Matrix Prices
                                </button>
                            </div>

                            <div className="col-md-3">
                                <button
                                    type="button"
                                    className="btn btn-danger"
                                    onClick={() => this.deleteSelectedPrices()}
                                >
                                    Delete Selected Prices
                                </button>
                            </div>
                            <div className="col-md-3">
                                <button
                                    type="button"
                                    className="btn btn-danger"
                                    onClick={() =>
                                        this.deleteAllBespokePrices()
                                    }
                                >
                                    Delete All Bespoke Prices
                                </button>
                            </div>
                        </div>
                        <div className="col-md-5 d-flex ">
                            {/* <button type="button" className="btn btn-success">
                                Select Prices To Include
                            </button> */}
                            <div className="col-md-4">
                                <button
                                    type="button"
                                    className="btn btn-warning"
                                    onClick={this.openQuoteStatusModal}
                                    // onClick={this.openCustQuote}
                                >
                                    Change Quote Status
                                </button>
                            </div>
                            <div className="col-md-4">
                                <button
                                    type="button"
                                    className="btn btn-success"
                                    onClick={this.openCustQuote}
                                >
                                    View Completed Quote
                                </button>
                            </div>
                            <div className="col-md-4 ">
                                <button
                                    type="button"
                                    className="btn btn-success"
                                    onClick={this.openViewAllPrices}
                                >
                                    View All Prices
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SelectPricesToInclude;
