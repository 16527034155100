import React from 'react';
import { Apibase } from './Apibase';
import fetch from './refreshTokenFetch';

//import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
//import Button from 'react-bootstrap/button';
import { download_table_as_csv } from './TableToExcel';

class ViewAllPricesModalForm extends React.Component {
    state = {
        AllPrices: false,
        IncludedOnly: false,
        RecommendedOnly: false,
        RemoveIncumbent: false,
        IncludeComms: false,
    };

    componentDidMount() {
        this.getAllPrices(this.props.QuoteID);
    }

    getAllPrices = (QuoteID) => {
        fetch(
            Apibase + '/api/v1/prices/byQuoteAllDetailincCurrent/' + QuoteID,
            {
                headers: {},
                method: 'GET',
            }
        )
            .then((res) => res.json())
            .then(
                (result) => {
                    this.setState({
                        AllPrices: result,
                    });
                    // console.log(result);
                    // console.log(QuoteID);
                    // this.loadSpinner(false);
                    //console.log('hit here third')
                },

                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        isLoaded: false,
                        error,
                    });
                }
            );
    };

    // handleChange = (e) => {
    //     this.setState({ NewquoteStatus: e.target.value });
    // };

    render() {
        return (
            <Modal
                show={this.props.isOpen}
                onHide={this.props.closeModal}
                dialogClassName="modal-90w"
            >
                <Modal.Header closeButton>
                    <Modal.Title>All Prices Detailed</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* Code For creating the table here  */}
                    <div className="col-12">
                        <div className="row">
                            <div className="col-md-4">
                                <button
                                    type="button"
                                    className="btn btn-link"
                                    href="#"
                                    onClick={() =>
                                        download_table_as_csv(
                                            'allPriceDetailTable'
                                        )
                                    }
                                >
                                    Download as CSV
                                </button>
                            </div>
                            <div className="col-md-8">
                                <div className="row">
                                    <div className="row ">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="Included"
                                            defaultChecked={
                                                this.state.IncludedOnly
                                            }
                                            onChange={() =>
                                                this.setState({
                                                    IncludedOnly:
                                                        !this.state
                                                            .IncludedOnly,
                                                })
                                            }
                                        ></input>
                                        <label
                                            className="form-check-label"
                                            for="Included"
                                        >
                                            Show Included Only
                                        </label>
                                        <div className="vl ml-1"></div>
                                    </div>
                                    <div className="row ml-5">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="Recommended"
                                            defaultChecked={
                                                this.state.RecommendedOnly
                                            }
                                            onChange={() =>
                                                this.setState({
                                                    RecommendedOnly:
                                                        !this.state
                                                            .RecommendedOnly,
                                                })
                                            }
                                        ></input>
                                        <label
                                            className="form-check-label"
                                            for="Recommended"
                                        >
                                            Show Recommended Only
                                        </label>
                                        <div className="vl ml-1"></div>
                                    </div>
                                    <div className="row ml-5">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="Included"
                                            defaultChecked={
                                                this.state.RemoveIncumbent
                                            }
                                            onChange={() =>
                                                this.setState({
                                                    RemoveIncumbent:
                                                        !this.state
                                                            .RemoveIncumbent,
                                                })
                                            }
                                        ></input>
                                        <label
                                            className="form-check-label"
                                            for="Incumbent"
                                        >
                                            Remove Incumbent
                                        </label>
                                        <div className="vl ml-1 mr-2"></div>
                                    </div>
                                    <div className="row ml-5">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="Comms"
                                            defaultChecked={
                                                this.state.IncludeComms
                                            }
                                            onChange={() =>
                                                this.setState({
                                                    IncludeComms:
                                                        !this.state
                                                            .IncludeComms,
                                                })
                                            }
                                        ></input>
                                        <label
                                            className="form-check-label"
                                            for="Comms"
                                        >
                                            Show Comms
                                        </label>
                                        <div className="vl ml-1 mr-2"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="table-responsive quotesTable mb-3 smtext">
                            {this.state.AllPrices ? (
                                <table
                                    id="allPriceDetailTable"
                                    className="table  table-bordered table-striped table-hover "
                                >
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Meter Number</th>
                                            <th>Price Name</th>
                                            <th>site address</th>
                                            <th>Start Date</th>
                                            <th>End Date</th>
                                            <th>Rate One</th>
                                            <th>Rate Two</th>
                                            <th>Rate Three</th>
                                            <th>AQ One</th>
                                            <th>AQ Two</th>
                                            <th>AQ Three</th>
                                            <th>FITS</th>
                                            <th>Standing Charge</th>
                                            <th>Standing Charge Frequency</th>
                                            <th>Incumbent</th>
                                            <th>Include</th>
                                            <th>Recommend</th>
                                            <th>Annual Cost</th>
                                            <th>Saving Or (Increase)</th>
                                            <th>Supplier</th>
                                            <th>Term</th>
                                            <th>Plan</th>
                                            {this.state.IncludeComms ? (
                                                <th>PPKWH Comms</th>
                                            ) : null}
                                            {this.state.IncludeComms ? (
                                                <th>SC Comms</th>
                                            ) : null}
                                        </tr>
                                    </thead>
                                    <tbody id="allPriceDetailBody" className="">
                                        {this.state.AllPrices.map(
                                            (item, key) => {
                                                let splitPriceName =
                                                    item.PriceName.replace(
                                                        /~/g,
                                                        ''
                                                    )
                                                        .replace(/null/g, '')
                                                        .split(',');
                                                // console.log(splitPriceName);
                                                //The double !! in the following if statement is to convert the 1's and 0's to booleen so that a triple = sign will work for comparison.
                                                if (
                                                    (this.state.IncludedOnly ===
                                                        !!item.Include ||
                                                        !this.state
                                                            .IncludedOnly) &&
                                                    (this.state
                                                        .RecommendedOnly ===
                                                        !!item.Recommend ||
                                                        !this.state
                                                            .RecommendedOnly ||
                                                        !!item.Incumbent) &&
                                                    (this.state
                                                        .RemoveIncumbent !==
                                                        !!item.Incumbent ||
                                                        !this.state
                                                            .RemoveIncumbent)
                                                ) {
                                                    return (
                                                        <tr
                                                            key={key}
                                                            className={''}
                                                        >
                                                            <td>
                                                                {item.PriceIDPK}
                                                            </td>
                                                            <td>
                                                                {
                                                                    item.MeterNumber
                                                                }
                                                            </td>
                                                            <td>
                                                                {item.PriceName.replace(
                                                                    /~/g,
                                                                    ''
                                                                ).replace(
                                                                    /null/g,
                                                                    ''
                                                                )}
                                                            </td>
                                                            <td>
                                                                {
                                                                    item.FullSiteAddress
                                                                }
                                                            </td>
                                                            <td>
                                                                {item.StartDate ===
                                                                '00/00/0000'
                                                                    ? ''
                                                                    : item.StartDate}
                                                            </td>
                                                            <td>
                                                                {item.EndDate}
                                                            </td>
                                                            <td>
                                                                {parseFloat(
                                                                    item.DayRate
                                                                ).toFixed(4)}
                                                            </td>
                                                            <td>
                                                                {parseFloat(
                                                                    item.NightRate
                                                                ).toFixed(4)}
                                                            </td>
                                                            <td>
                                                                {parseFloat(
                                                                    item.OtherRate
                                                                ).toFixed(4)}
                                                            </td>
                                                            <td>
                                                                {item.DayAQ}
                                                            </td>
                                                            <td>
                                                                {item.NightAQ}
                                                            </td>
                                                            <td>
                                                                {item.OtherAQ}
                                                            </td>
                                                            <td>
                                                                {parseFloat(
                                                                    item.FITS
                                                                ).toFixed(4)}
                                                            </td>
                                                            <td>
                                                                {parseFloat(
                                                                    item.StandingCharge
                                                                ).toFixed(4)}
                                                            </td>
                                                            <td>
                                                                {
                                                                    item.StandingChargeFrequency
                                                                }
                                                            </td>
                                                            <td>
                                                                {item.Incumbent
                                                                    ? 'Yes'
                                                                    : 'No'}
                                                            </td>
                                                            <td>
                                                                {item.Include
                                                                    ? 'Yes'
                                                                    : 'No'}
                                                            </td>
                                                            <td>
                                                                {item.Recommend
                                                                    ? 'Yes'
                                                                    : 'No'}
                                                            </td>
                                                            <td>
                                                                {parseFloat(
                                                                    item.AnnualCost
                                                                ).toFixed(2)}
                                                            </td>
                                                            <td
                                                                className={
                                                                    item.CostDiff <
                                                                    0
                                                                        ? 'text-danger'
                                                                        : 'text-success'
                                                                }
                                                            >
                                                                {item.CostDiff <
                                                                0
                                                                    ? '(' +
                                                                      parseFloat(
                                                                          item.CostDiff
                                                                      ).toFixed(
                                                                          2
                                                                      ) +
                                                                      ')'
                                                                    : parseFloat(
                                                                          item.CostDiff
                                                                      ).toFixed(
                                                                          2
                                                                      )}
                                                            </td>
                                                            <td>
                                                                {
                                                                    splitPriceName[0]
                                                                }
                                                            </td>
                                                            <td>
                                                                {splitPriceName[1]
                                                                    ? splitPriceName[1].replace(
                                                                          ' term:',
                                                                          ''
                                                                      )
                                                                    : ''}
                                                            </td>
                                                            <td>
                                                                {splitPriceName[2]
                                                                    ? splitPriceName[2].replace(
                                                                          ' plan type:',
                                                                          ''
                                                                      )
                                                                    : ''}
                                                            </td>

                                                            {this.state
                                                                .IncludeComms ? (
                                                                <td>
                                                                    {
                                                                        item.PPKWHComms
                                                                    }
                                                                </td>
                                                            ) : null}
                                                            {this.state
                                                                .IncludeComms ? (
                                                                <td>
                                                                    {
                                                                        item.SCComms
                                                                    }
                                                                </td>
                                                            ) : null}
                                                        </tr>
                                                    );
                                                } else {
                                                    return null;
                                                }
                                            }
                                        )}
                                    </tbody>
                                </table>
                            ) : null}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {/* <Button
                        variant="primary"
                        type="submit"
                        onClick={() =>
                            this.props.handleSubmit(
                                this.state.PPKWH,
                                this.state.SCComms
                            )
                        }
                    >
                        Submit
                    </Button> */}
                </Modal.Footer>
            </Modal>
        );
    }
}

export default ViewAllPricesModalForm;
