import { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { Button } from 'react-bootstrap';

import { Apibase } from './Apibase';
import fetch from './refreshTokenFetch';

const BasketManagement = () => {
    const [baskets, setbaskets] = useState(false);
    const [showBasketModal, setShowBasketModal] = useState(false);
    const [selectedBasket, setSelectedBasket] = useState(undefined);

    const toggleBasketModal = () => {
        if (showBasketModal) setSelectedBasket(undefined);
        setShowBasketModal(!showBasketModal);
    };

    const getBaskets = () => {
        fetch(Apibase + '/api/v1/baskets/getAll2', {
            headers: {},
            method: 'GET',
        })
            .then((res) => res.json())
            .then(
                (result) => {
                    result.length > 0 ? setbaskets(result) : setbaskets(false);
                },
                (error) => {
                    console.log(error);
                }
            );
    };

    useEffect(() => {
        getBaskets();
    }, []);

    const setStatus = (status, basketIndex) => {
        fetch(Apibase + '/api/v1/baskets/edit', {
            headers: { 'Content-Type': 'application/json' },
            method: 'PUT',
            body: JSON.stringify({
                BasketIDPK: baskets[basketIndex].BasketIDPK,
                BasketName: baskets[basketIndex].BasketName,
                Active: status ? 1 : 0,
            }),
        })
            .then((res) => res.json())
            .then(
                (result) => {
                    getBaskets();
                },
                (error) => {
                    console.log(error);
                }
            );
    };

    const editCreateBasket = (basketName) => {
        const method = selectedBasket ? 'PUT' : 'POST';
        const url = selectedBasket
            ? '/api/v1/baskets/edit'
            : '/api/v1/baskets/create';

        fetch(Apibase + url, {
            headers: { 'Content-Type': 'application/json' },
            method: method,
            body: JSON.stringify({
                BasketIDPK: selectedBasket
                    ? selectedBasket.BasketIDPK
                    : undefined,
                BasketName: basketName,
                Active: selectedBasket ? selectedBasket.Active : 1,
            }),
        })
            .then((res) => res.json())
            .then(
                (result) => {
                    getBaskets();
                    toggleBasketModal();
                },
                (error) => {
                    console.log(error);
                }
            );
    };

    if (!baskets) return null;

    return (
        <>
            <div className="card">
                <div className="container">
                    <div
                        className="cardHead"
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            margin: '10px 0',
                        }}
                    >
                        <h2>Baskets</h2>
                        <button
                            type="button"
                            className="btn btn-info"
                            onClick={() => {
                                setSelectedBasket(false);
                                toggleBasketModal();
                            }}
                        >
                            Create New Basket
                        </button>
                    </div>
                    <div className="table-responsive basketsTable" style={{maxHeight:'700px'}}>
                        <table
                            id="basketsTable"
                            className="table table-bordered table-sm"
                        >
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Basket Name</th>
                                    <th>Active</th>
                                    <th>Edit</th>
                                </tr>
                            </thead>
                            <tbody>
                                {baskets.map((basket, index) => (
                                    <tr key={basket.BasketIDPK}>
                                        <td>{basket.BasketIDPK}</td>
                                        <td>{basket.BasketName}</td>
                                        <td>
                                            {basket.Active
                                                ? 'Active'
                                                : 'Disabled'}
                                        </td>
                                        <td>
                                            <button
                                                type="button"
                                                className="btn btn-info"
                                                style={{
                                                    width: '100px',
                                                }}
                                                onClick={() =>
                                                    setStatus(
                                                        !basket.Active,
                                                        index
                                                    )
                                                }
                                            >
                                                {basket.Active
                                                    ? 'Deactivate'
                                                    : 'Activate'}
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-info"
                                                style={{
                                                    marginLeft: '8px',
                                                    width: '150px',
                                                }}
                                                onClick={() => {
                                                    setSelectedBasket(basket);
                                                    toggleBasketModal();
                                                }}
                                            >
                                                Change Name
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <EditBasketModal
                isOpen={showBasketModal}
                closeModal={toggleBasketModal}
                handleSubmit={editCreateBasket}
                selectedBasket={selectedBasket}
            />
        </>
    );
};

const EditBasketModal = (props) => {
    const [basketName, setBasketName] = useState();

    useEffect(() => {
        setBasketName(props.selectedBasket?.BasketName || undefined);
    }, [props.selectedBasket]);

    return (
        <Modal show={props.isOpen} onHide={props.closeModal}>
            <Modal.Header closeButton>
                <Modal.Title>Please Enter a name for the basket</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group>
                    <Form.Label>Basket Name:</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Basket name..."
                        defaultValue={basketName}
                        onChange={(e) => setBasketName(e.target.value)}
                        key={props.selectedBasket?.BasketName || 'newBasket'}
                    />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="primary"
                    type="submit"
                    onClick={() => props.handleSubmit(basketName)}
                >
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default BasketManagement;
